import type {
  LinksFunction,
  LoaderArgs,
  V2_MetaFunction } from
'@remix-run/node';
import { redirect, json } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'@remix-run/react';

import NavBar from './components/NavBar';
import { getUser } from './models/session.server';

import tailwindStylesheetUrl from './styles/tailwind.css';
import { ErrorBoundary } from './ErrorBoundary';
import { withSentry } from "@sentry/remix";
import Footer from './components/Footer';
import { useEffect } from 'react';

export const links: LinksFunction = () => {
  return [
  { rel: 'stylesheet', href: tailwindStylesheetUrl },
  { rel: 'icon', type: 'image/x-icon', href: '/icon.png' }];

};

export const meta: V2_MetaFunction = () => [
{
  charset: 'utf-8',
  title: 'EngageTV Creative Portal', //ToDo:- change Title 
  viewport: 'width=device-width,initial-scale=1'
}];


export async function loader({ request }: LoaderArgs) {
  try {
    return json({
      user: await getUser(request),
      isError: false,
      error: null,
      ENV: {
        SENTRY_DSN: process.env.SENTRY_DSN || ''
      }
    });
  } catch (err: any) {
    return redirect(`/logout}`);
    /* return json({
      user: null,
      isError:true,
      error:err,
      ENV: {
        SENTRY_DSN: process.env.SENTRY_DSN || '',
      },
    }) */
  }
}

declare global {
  interface Window {
    google: {
      translate: {
        TranslateElement: any; // Adjust the type if possible
      };
    };
    googleTranslateElementInit: () => void;
  }
}

function App() {
  const data = useLoaderData<typeof loader>();

  useEffect(() => {
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        { pageLanguage: 'en' },
        'google_translate_element'
      );
    };

    const addScript = document.createElement('script');
    addScript.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit&gaTrack=false';
    document.body.appendChild(addScript);

    window.googleTranslateElementInit = googleTranslateElementInit;

  }, []);


  return (
    <html lang="en">
      <head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />

        <Meta />
        <Links />
      </head>
      <body>
        <header>
          <NavBar />
        </header>
        {data.isError ? <ErrorBoundary error={data.error} /> : <Outlet />}

        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(
              data.ENV
            )}`
          }} />

        <script
          async
          src="https://js.stripe.com/v3/pricing-table.js">
        </script>
        <Scripts />
        <LiveReload />
        <Footer />
      </body>
    </html>);

}

export default withSentry(App);